<template>
    <div>
        <EmploymentNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Employment</h1>

            <img :src="`${$cdiVars.BASE_URL}/images/v4/employment.jpg`" class="img-responsive img_content_right">

            <h4>Join our fast paced hard working team</h4>

            <p>CDI is the world's fastest curb manufacturer. We manufacture curbs for the HVAC industry and are growing with many opportunities for career advancement.  We are a nation-wide company with manufacturing facilities located in the <router-link to="/locations">suburbs of Minneapolis, Dallas, Seattle, Charlotte, and Phoenix</router-link>.</p>

            <p>We encourage personal growth and offer career advancement to individuals who demonstrate initiative. Training is available for many positions.</p>

            <p>Departments include Welding, Insulating, Turret Operation, Engineering, Sales, Finance, Administration, IT and Human Resources.</p>

            <p>CDI employees are offered the following benefits:</p>

            <ul>
                <li>401(k) with match</li>
                <li>Health & Dental insurance</li>
                <li>Life Insurance</li>
                <li>Short Term and Long Term Disability Insurance</li>
                <li>Paid Holidays and Vacation</li>
                <li>Life Supplemental Program</li>
                <li>Profit Sharing Opportunities</li>
            </ul>

            <p>CDI is an Equal Opportunity Employer.</p>

            <a href="https://recruiting.paylocity.com/recruiting/jobs/List/5139/CDI-Custom-Curb-Adapters" target="_blank" class="btn btn-primary btn-lg">View Job Openings</a>

            <div class="row">
                <iframe class="col-sm-12" style="height: 500px; margin-top: 1em; margin-bottom: 1em" src="https://www.youtube.com/embed/Mdeko0HHXas" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </main>
    </div>
</template>

<script>
import EmploymentNav from '@/components/content/EmploymentNav.vue'
export default {
    name: "Employment",
    components: {
        EmploymentNav,
    },
    created() {
        this.$appStore.setTitle(['CDI Employment']);
    },
}
</script>

<style scoped>

</style>